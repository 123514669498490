<template lang="html">
  <div class="form" v-if="globalSettings">
    <div class="form__heading">
      <Heading
        :text="globalSettings['labels_and_titles']['leave_your_message']"
        :hType="2"
        textAlign="center"
      />
    </div>

    <div class="form__fields">
      <div class="form__row">
        <div class="form__col">
          <InputText
            :label="`${globalSettings['labels_and_titles']['from']}*`"
            :placeholder="globalSettings['labels_and_titles']['your_name']"
            :isError="!validation.candle_from.valid"
            :errorMessage="validation.candle_from.error"
            v-model="formData.candle_from"
            @change="validateField('candle_from')"
          />
        </div>
      </div>
      <div class="form__row">
        <div class="form__col">
          <InputText
            :label="`${globalSettings['labels_and_titles']['for']}`"
            :placeholder="
              globalSettings['labels_and_titles']['who_is_the_message_for']
            "
            :isError="!validation.candle_for.valid"
            :errorMessage="validation.candle_for.error"
            v-model="formData.candle_for"
            @change="validateField('candle_for')"
          />
        </div>
      </div>
      <div class="form__row">
        <div class="form__col">
          <Textarea
            :label="`${globalSettings['labels_and_titles']['message']}*`"
            :placeholder="globalSettings['labels_and_titles']['your_message']"
            :isError="!validation.message.valid"
            :errorMessage="validation.message.error"
            v-model="formData.message"
            @change="validateField('message')"
          />
        </div>
      </div>
    </div>
    <div class="form__buttons">
      <div class="form__button">
        <Button @click="submitForm">
          <span v-if="globalSettings">
            {{ globalSettings['button_names']['next_step'] }}
          </span>
        </Button>
      </div>
      <div class="form__button">
        <Button styleButton="border" @click="cancelForm">
          <span v-if="globalSettings">
            {{ globalSettings['button_names']['back_button'] }}
          </span>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/InputText/InputText.vue';
import Textarea from '@/components/Textarea/Textarea.vue';
import Button from '@/components/Button/Button.vue';
import Heading from '@/components/Heading/Heading.vue';

export default {
  name: 'FormMessage',
  components: {
    InputText,
    Textarea,
    Button,
    Heading,
  },
  props: {
    initData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      formData: {
        candle_from: this.initData.candle_from,
        candle_for: this.initData.candle_for,
        message: this.initData.message,
      },
      validation: {
        candle_from: {
          valid: true,
          required: true,
          error: '',
        },
        candle_for: {
          valid: true,
          required: false,
          error: '',
        },
        message: {
          valid: true,
          required: true,
          error: '',
        },
      },
    };
  },
  watch: {
    formData: {
      deep: true,
      handler(val) {
        this.$emit('change-data', val);
      },
    },
  },
  computed: {
    globalSettings() {
      return this.$store.state.global.settings;
    },
  },
  methods: {
    validateField(field) {
      if (this.validation[field] && this.validation[field].required) {
        this.validation[field].valid = this.formData[field] !== '';
        if (!this.validation[field].valid) {
          this.validation[field].error =
            this.globalSettings['labels_and_titles']['form_errors']['required'];
          return;
        }
      }
    },
    submitForm() {
      const validValuesArr = [];
      for (const field in this.validation) {
        this.validateField(field);
        validValuesArr.push(this.validation[field].valid);
      }

      if (validValuesArr.every((el) => el === true)) {
        this.$emit('submitForm', {
          formData: this.formData,
        });
      }
    },
    cancelForm() {
      this.$emit('cancelSubmit');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>

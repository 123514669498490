<template lang="html">
  <div class="stones-form__step-content">
    <FormMessage
      :initData="previewData"
      @submitForm="submitForm"
      @cancelSubmit="cancelSubmit"
      @change-data="changeData"
    />
  </div>
</template>

<script>
// import FormData from '@/components/Form/FormData.vue';

import FormMessage from '@/components/Form/FormMessage.vue';

export default {
  name: 'StonesFormStep0',
  components: {
    FormMessage,
    // FormData,
  },
  computed: {
    previewData() {
      return this.$store.state.stones.previewData;
    },
  },
  methods: {
    // submitForm() {
    //   // console.log(e);
    //   this.$store.commit('stones/setFormCurrentStep', 2);
    // },
    // cancelSubmit() {
    //   this.$store.commit('stones/setFormStepsIsShow', false);
    // },
    changeData(e) {
      this.$emit('change-data', e);
    },
    submitForm({ formData }) {
      const previewData = {
        candle_from: formData.candle_from,
        candle_for: formData.candle_for,
        message: formData.message,
      };
      this.$store.commit('stones/setPreviewData', previewData);
      this.$store.commit('stones/setFormCurrentStep', 2);
    },
    cancelSubmit() {
      this.$store.commit('stones/setFormStepsIsShow', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';

:deep() {
  .textarea__label {
    color: #fff;
  }
  .form__button[data-v-5ab04430]:not(:first-child) {
    margin-top: 0;
  }
}
</style>
